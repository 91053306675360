<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top>
                  <b-tbody>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('elearning_config.fiscal_year') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</b-td>
                      <b-th style="width: 15%" class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('elearning_config.organization') }}</b-th>
                      <b-th class="text-center" style="width: 5%" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">:</b-th>
                      <b-td style="width: 30%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('elearning_config.office_type') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.office') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('elearning_tpm.vanue') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.venue_bn : formData.venue }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('elearning_venue.total_capacity') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $n(formData.total_capacity) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('elearning_venue.rent_amount') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $n(formData.rent_amount) }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.contact_person_no') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">
                        {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(formData.contact_person_no, { useGrouping: false }) }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('globalTrans.address') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ formData.address }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.reason') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.reason_bn : formData.reason }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('globalTrans.start_date') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ formData.start_date | dateFormat }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.end_date') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ formData.end_date | dateFormat }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th colspan="6" class="text-center text-primary">{{$t('elearning_venue.reservation_info')}}</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('globalTrans.name') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.name_bn : formData.name }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.mobile') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(formData.mobile, { useGrouping: false }) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%" class="text-right" >{{ $t('globalTrans.email') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ formData.email }}</b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('globalTrans.address') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.reservation_address_bn : formData.reservation_address_bn }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('elearning_config.organization') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.reservation_org_bn : formData.reservation_org }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr/>
            <b-row>
              <b-col lg="10" sm="12">
                <b-overlay :show="loading">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <ValidationProvider name="Reject Reason (En)"  vid="reject_reason" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="reject_reason"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_venue.reject_reason_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-textarea
                              rows="2"
                              id="reject_reason"
                              v-model="formData.reject_reason"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Reject Reason (Bn)"  vid="reject_reason_bn" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="reject_reason_bn"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_venue.reject_reason_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-textarea
                              rows="2"
                              id="reject_reason_bn"
                              v-model="formData.reject_reason_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.reject') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { venueReservationUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.gentFormData()
      this.formData = tmp
      this.formData.status = 5
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        org_id: 0
      },
      officeTypeList: [],
      guestHouseList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
        this.officeTypeList = this.officeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
        this.guestHouseList = this.guestList(newValue)
    }
  },
  methods: {
    gentFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    officeList (orgId) {
       const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
       if (orgId) {
         return office.filter(item => item.org_id === orgId)
       }
       return office
    },
    guestList (officeId) {
       const guest = this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
       if (officeId) {
         return guest.filter(item => item.office_type_id === officeId)
       }
       return guest
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${venueReservationUpdate}/${this.id}`, this.formData)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
        this.$router.push('/training-e-learning-service/venue/pending-venue-reservation')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
